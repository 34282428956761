.header {
  height: 1000px;

  &.show {
    background-color: #0d1220;
  }
  &__container {
    width: 1160px;
  }
  .burger-menu {
    display: none;
    z-index: 3;
  }
  .header__components {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .logo__container {
      z-index: 3;
    }
    .header__nav {
      display: flex;
      gap: 190px;
      text-align: center;
      width: 100%;

      margin-top: 20px;
      margin-bottom: 20px;

      .nav__list {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 79px;

        @media screen and (max-width: 929px) {
          gap: 30px;
        }

        .nav__item {
          position: relative;
          font-size: 14px;
          line-height: 20px;
          &:not(:last-child) {
            margin-right: 40px;
          }
          &.visible-menu {
            padding-bottom: 20px;
          }
          &-dropdown {
            display: flex;
            transform: scale(0);
            flex-direction: column;
            left: -40px;
            top: 40px;
            padding-top: 50px;
            transition: 0.3s;
            position: absolute;
            background-color: #313f61;
            border-radius: 12px;
            gap: 16px;
            padding: 24px !important;

            &::after {
              content: "";
              position: absolute;
              border-style: solid;
              top: -20px;
              border-width: 27px 34px 0px 29px;
              border-color: transparent #313f61 transparent transparent;
            }
            .dropdown__item {
              white-space: nowrap;
              text-align: left;

              a {
                color: white;
              }
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
            }
          }
          a {
            color: white;
            text-decoration: none;
            transition: 0.5s;
            &:hover {
              color: white;
            }
            &.selected {
              color: white;
            }
            &.visible {
              padding-bottom: 20px;
            }
          }
        }
      }
    }
  }




  .welcome__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 150px;
    text-align: center;
  
    .welcome__text {
      width: 60%;
      color: white;
      .welcome__title {
        font-size: 50px;
        line-height: 56px;
        /* identical to box height, or 112% */
  
        letter-spacing: -0.02em;
        img {
          height: 47px;
        }
      }
      .welcome__subititle {
        padding-top: 16px;
      }
      .welcome__desc {
        padding-top: 16px;
      }
      .buttons__container {
        display: flex;
        padding: 32px 0px;
        gap: 20px;
        align-items: center;
        justify-content: center;
      }
      .advantages__container {
        padding-top: 56px;
        display: flex;
        gap: 30px;
      }
    }
    .bg__container {
      position: absolute;
      width: 800px;
      height: 500px;
      right: -32%;
      margin-top: -100px;
      img {
        mix-blend-mode: lighten;
      }
    }
    @media screen and (max-width: 929px) {
      .welcome__text {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        align-items: center;
      }
  
      .bg__container {
        display: none;
      }
    }
  }


  .advantage__desc {
    font-size: 14px;
    line-height: 20px;
  }
  @media screen and (max-width: 767px) {
    .advantages__container {
      flex-wrap: wrap;
      .advantage__item {
        flex: 0 0 45%;
      }
    }
  }


  .social__list {
    display: flex;
    .social__item {
      &:not(:last-child) {
        margin-right: 18px;
      }
    }
  }
  @media screen and (max-width: 969px) {
    .burger-menu {
      display: block;
      position: absolute;
      width: 30px;
      height: 20px;
      right: 20px;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 3px;
        background-color: white;
        transition: 0.2s;
      }
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 3px;
        background-color: white;
        transition: 0.2s;
      }
      span {
        position: absolute;
        width: 100%;
        height: 3px;
        left: 0;
        top: 9px;
        background-color: white;
      }
      &.opened {
        &::after {
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
        }
        &::before {
          bottom: 50%;
          transform: translateY(50%) rotate(45deg);
        }
        span {
          display: none;
        }
      }
    }
    .logo__container {
      width: 60px;
    }
    .header__nav {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #0d1220;
      transform: translateX(-100%);
      padding: 100px 0px 0px 0px;
      transition: 0.3s;

      z-index: 2;
      .nav__list {
        flex-direction: column;
        text-align: left;
        .nav__item {
          margin: 0px 0px 20px 25px !important;
        }
      }
      &.opened {
        overflow: auto;

        display: block;
        transform: translateX(0);
      }
    }
    .social__list {
      width: 100%;
      transform: translateX(-100%);
      z-index: 2;
      top: 370px;
      flex-direction: row;
      margin: 0px 0px 0px 25px !important;
      transition: 0.5s;

      &.opened {
        display: flex !important;
        transform: translateX(0);
      }
    }
  }
}
.nav__item-dropdown {
  &.active {
    transform: scale(1) !important;
    @media screen and (max-width: 969px) {
      position: relative !important;
      width: 170px !important;

      left: 0 !important;
      top: 10px !important;
      &::after {
        display: none;
      }
    }
  }
}
