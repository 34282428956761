$mainColor: #0d1220;
$blackColor: #010101;
$whiteText: #fff;

.full {
  overflow: hidden;
  width: 100%;

  font-family: Cy;

  .wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1160px;
    margin: 0 auto;
    padding: 10px 25px;
  }
}

.background__main {
  background: url("./img/bg_main.jpg") no-repeat;
  background-size: 100% 100%;
}

.email__errors {
  color: rgb(244, 61, 61);
}
