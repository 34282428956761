.footer {
  background: url("../../img/bg_footer.jpg") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  color: black;
  position: relative;

  .footer__container {
    display: flex;
    flex-direction: column;

    .footer__subscribe-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .subscribe__title {
        font-size: 26px;
        padding-bottom: 15px;
      }
      form {
       
      }
      .input__container {
        margin: 0 auto;
        display: inline-flex;
        input {
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 10px 0px 0px 10px;
        }
        button {
          border: none;
          color: white;
          font-size: 16px;
          background: linear-gradient(
            96.44deg,
            #207811 -21.27%,
            #19aa39 -21.25%,
            #9ec600 121.19%
          );
          padding: 9px;
          border-radius: 0px 10px 10px 0px;
        }
      }
    }
    .footer__header {
      padding-bottom: 84px;
      display: flex;
      justify-content: space-between;
      .social__list {
        display: flex;
        gap: 16px;
      }
    }
    .footer__body {
      padding-bottom: 50px;
      border-bottom: 1px solid black;

      .footer__columns {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .footer__column {
          max-width: 207px;
          min-width: 150px;

          .footer__column-title {
            color: #000;
            font-size: 22px;

            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 15px;

            margin-bottom: 30px;
          }

          .footer__list {
            p {
              padding-bottom: 16px;
              position: relative;
              font-weight: 500;
              font-size: 16px;
              /* identical to box height, or 150% */

              /* Neutrals/2 */
              color: #171f32;
              &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: #d7ecbb;
                height: 1px;
                content: "";
              }
            }
            .footer__item {
              .footer__link {
                color: #000;
                font-size: 12px;
                line-height: 28px;
                text-decoration: none;
              }
            }
          }
        }
        .footer__column-newsletter {
          max-width: 250px;
        }
      }
    }
  }
  .footer__bottom {
    p {
      margin-bottom: 30px;
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer {
    padding: 50px 0px;

    .footer__header {
      padding-bottom: 50px !important;
    }
    .footer__body {
      padding-bottom: 50px !important;
    }

    .footer__columns {
      gap: 20px;
      .footer__column {
        .footer__list {
          p {
            padding-bottom: 5px !important;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
