.main__component {
  // border: 1px red solid;
  margin-top: -400px;
  .main__container {
    padding-top: 152px;
    @media screen and (max-width: 767px) {
      padding-top: 80px;
    }
  }
}
