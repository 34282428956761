.apps__section {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 70px;
  }

  .apps__section__container {
    display: flex;
    justify-content: center;
  }
}
