.apps__container {
  display: flex;
  flex-direction: column;

  margin-bottom: 40px;

  .app__text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-top: 15px;

    .app__title {
      text-align: center;
      font-size: 12px;
    }
  }
  .app__img-container {
    display: flex;
    justify-content: center;

    img {
      width: 70%;
    }
  }
}

.reversed {
  flex-direction: row-reverse;
}

@media screen and (max-width: 929px) {
  .apps__container {
    flex-direction: column;
  }
}
