@import "../../App.scss";

.button__content::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255, 0.60);
  border-radius: 28px;
  mix-blend-mode: color;
}

.button__content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  border-radius: 28px;
}

.default__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 28px;
  color: white;
  gap: 10px;
  font-size: 14px;
  border: solid 1px white;
  &.first {
    background: linear-gradient(90deg, #D48E25 0.09%, #C38228 20.17%, #96642F 60.07%, #634238 99.98%);
  }
  &.second {
    background: linear-gradient(90deg, #FFDDC1 -0.02%, #E6A025 7.33%, #D6963C 13.44%, #C58C52 17.46%, #B0806F 23.72%, #A17784 29.98%, #987290 36.16%, #957094 42.18%, #0D4F80 81.16%, #00274E 100.01%);
  }
}
