.feature__container {
  display: flex;
  gap: 125px;
  padding-bottom: 152px;

  width: 90%;  

  .feature__text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .feature__title {
      font-size: 32px;
      line-height: 40px;
      padding-top: 16px;
      padding-bottom: 20px;

      letter-spacing: -0.01em;
    }
    .feature__desc {
      font-weight: 400;
      font-size: 18px;
      padding-top: 16px;
      line-height: 40px;
      /* or 150% */
    }
  }
  .feature__img-container {
    flex: 0 0 40%;
    display: flex;
    align-items: center;
  }
}

.reversed {
  flex-direction: row-reverse;
}

@media screen and (max-width: 929px) {
  .feature__container {
    flex-direction: column;
  }
}
